.header {
    position: fixed;
    width: 100%;
    z-index: 999;

    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 30px;
    }

    a {
        text-decoration: none;
    }
}