.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;

    @media only screen and (max-width: 600px) {
      gap: 2px;
    }
}