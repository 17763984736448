.button {
    border: none;
    border-radius: 30px;
    padding: 8px 24px;
    border: 2px solid #2E4A37;
    background-color: white;
    color: #2E4A37;
}

.button:hover {
    cursor: pointer;
    background-color: #2E4A37;
    color: white;
}

// .primary {
//     border-radius: 30px;
//     padding: 8px 24px;
//     color: white;
// }

// .front {
//     border: 4px solid #2E4A37;
//     background-color: #2E4A37;
// }

// .front:hover {
//     cursor: pointer;
//     transform: translate(-4px, -6px);
// }