@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&family=Bebas+Neue&family=Pangolin&family=Poppins:wght@400;500;600;700&family=Rubik:wght@400;600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

html {
  /* Firefox */
  /* scrollbar-width: none; */
  /* IE and Edge */
  /* -ms-overflow-style: none; */
  /* overflow:none; */
  /* height: 100%; */
}

html::-webkit-scrollbar {
  /* display: none; */
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #89b188;
  height: 100%;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Bebas Neue', 'Poppins',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
