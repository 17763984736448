.container {
    background-color: #89B188;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.tabContainer {
    display: flex;
    justify-content: center;
    align-items: start;
    padding-top: 40px;
    gap: 20px;
}

@media only screen and (max-width: 768px) {
    .container {
        height: auto;
        padding: 10vh 0;
    }
    .tabContainer {
        flex-direction: column;
    }
}