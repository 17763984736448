.container {
    display: grid;
    // text-align: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    // background: no-repeat url("../../assets/boba-hero-img-min.png");
    // background-position: center;
    // background-size: cover;
}

.headingContainer {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 16vw;
    z-index: 10;

    div {
        width: 100%;
    }
}

.headingText {
    text-align: left;
    // display: flex;
    // justify-content: left;
}

.iconLinks {
    margin: 4px 0 4px 0;
    display: flex;
    gap: 10px;
    a {
        color: #2E4A37 !important;
    }
}

.headingTextContainer {
    width: 56% !important;
    margin: 10px 0;

    @media only screen and (max-width: 600px) {
        width: 90vw !important
    }
}

.spaced {
    margin-top: 10px;
}

.imageContainer {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    height: 100vh;
    z-index: -2;
}

.image {
    // width: 100%;
    height: 100%;
}

.blobContainer {
    position: absolute;
    overflow:hidden;
    width: 100%;
    height: 100vh;
    grid-column: 1;
    grid-row: 1;
    z-index: -3;
}

.blob {
    position: absolute; 
}

.one {
    width: clamp(300px, 50vw, 1000px);  
    top: -10vw;
    left: -10vw;
    animation: blobAnim1 30s infinite linear; 
}

.two {
    width: clamp(400px, 50vw, 1000px);    
    bottom: -10vw;
    left: 70vw;
    animation: blobAnim2 28s infinite linear; 
}

@keyframes blobAnim1 {
    0% {
        rotate: 0;
        scale: 1;
        translate: 0 0;
    }
    25%{
        translate: -20px -20px;
    }
    50% {
        scale: 0.9;
        translate: -20px 20px;
    }
    75% {
        translate: 20px 20px;
    }
    100% {
        rotate: 359deg;
        scale: 1;
    }
}

@keyframes blobAnim2 {
    0% {
        rotate: 0;
        scale: 1;
        translate: 0 0;
    }
    25%{
        translate: -20px 20px;
    }
    50% {
        scale: 0.9;
        translate: 20px 20px;
    }
    75% {
        translate: -20px 20px;
    }
    100% {
        rotate: -359deg;
        scale: 1;
    }
}

@media (max-width: 40rem) {
    .headingContainer {
        margin-left: 4.6vw;
    }
}