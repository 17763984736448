.container {
  padding-top: 90px;
}

.centerAlign {
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding: 0 5vw;
  }
}

.imgContainer {
  margin: 20px 0 50px 0;
  padding: 0 20vw;

  @media only screen and (max-width: 600px) {
    margin-bottom: 26px;
    padding: 0 5vw;
  }
}

.img {
  width: 100%;
  border-radius: 1.6vw;
  box-shadow: 1vw 1vw 0 rgba(52, 84, 59, .5);
}

.projectImg {
  width: 100%;
}

.aboutContainer {
  padding: 40px 12vw;
  background-color: #4d7951;

  @media only screen and (max-width: 600px) {
      padding: 22px 5vw;
  }
}

.highlightPadded {
  padding: 0 12vw;

  @media only screen and (max-width: 600px) {
    padding: 0 5vw;
  }
}

.highlightContainer {
  margin-top: 44px;

  @media only screen and (max-width: 600px) {
    margin-top: 26px;
  }
}

.sectionTitle {
  background-color: #edf2eeb4;
  padding: 10px 12vw;
  margin: 32px 0 44px 0;
  text-align: center;

  @media only screen and (max-width: 600px) {
    margin: 20px 0 26px 0;
  }
}

.processContainer {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 24px;
  margin: 44px 12vw 66px 12vw;
  color: #EDF2EE;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin: 26px 5vw 30px 5vw;
    gap: 16px;
  }
}

.card {
  background-color: #4d7951;
  border-radius: 20px;
  padding: 40px 26px;
  width: 24vw;
  text-align: center;

  ul {
    padding-left: 20px;
    margin: 0;
  }

  @media only screen and (max-width: 600px) {
    width: 70vw;
    padding: 26px 20px;
  }
}

.cardTitle {
  text-align: center;
  margin-bottom: 16px;
}

.list {
  margin: 20px 0 0 0;

  li + li {
    margin-top: 8px;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 12px;
  }
}

.designChallengeImgContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 44px;

  img {
    max-width: 30%;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 30px;
    img {
      max-width: 80%;
    }
  }
}