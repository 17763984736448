.heading1 {
    display: block;
    font-family: "DM Sans", sans-serif;
    font-size: calc(2.2vw + 36px);
    font-weight: 500;

    @media only screen and (max-width: 600px) {
        display: none;
    }
}

.mobileHeading1 {
    display: none;
    font-family: "DM Sans", sans-serif;
    font-size: 60px;
    font-weight: 500;
    white-space: pre-wrap;

    @media only screen and (max-width: 600px) {
        display: block;
    }
}

.heading2 {
    display: block;
    font-family: "DM Sans", sans-serif;
    font-size: calc(2vw + 2px);

    @media only screen and (max-width: 600px) {
        display: none;
    }
}

.mobileHeading2 {
    display: none;
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    white-space: pre-wrap;

    @media only screen and (max-width: 600px) {
        display: block;
    }
}

.projectTitle {
    font-family: "Rubik", sans-serif;
    font-size: 44px;
    font-weight: 600;

    @media only screen and (max-width: 600px) {
        font-size: 32px;
    }
}

.subSectionTitle1 {
    font-family: "Rubik", sans-serif;
    font-size: clamp(24px, calc(2vw + 6px), 40px);
    font-weight: 600;
}

.sectionTitle1 {
    font-family: "Rubik", sans-serif;
    font-size: clamp(24px, calc(2vw + 12px), 40px);
    font-weight: 600;
}

.sectionTitle2 {
    font-family: "DM Sans", sans-serif;
    font-size: clamp(24px, calc(2vw + 8px), 40px);
    font-weight: 600;
}

.primaryButtonLabel {
    font-family: "Poppins", sans-serif;
    font-size: clamp(16px, 0.6vw + 10px, 28px);
}

.buttonLabel {
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
}

.cardTitle2 {
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
}

.cardTitle1 {
    font-family: "Poppins", sans-serif;
    font-size: clamp(16px, calc(1vw + 16px), 26px);
    font-weight: bold;
}

.cardDescription1 {
    font-family: "Rubik", sans-serif;
    font-size: 1.1rem;
    line-height: 1.6rem;
}

.cardDescription2 {
    font-family: "Poppins", sans-serif;
    font-size: clamp(16px, calc(1vw + 4px), 30px);
}

.label {
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
}

.tabLabel {
    font-family: "Poppins", sans-serif;
    font-size: calc(1vw + 16px);
    font-weight: 600;
}

.link {
    font-family: "Poppins", sans-serif;
    font-size: calc(1vw + 6px);
    font-weight: 600;
}

.listItem {font-family: "Poppins", sans-serif;
    font-size: calc(2vw + 2px);
}

.logo {
    font-family: "Fjalla One", sans-serif;
    // font-style: italic;
    font-size: 26px;
    font-weight: 500;
}

.navLink {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
}