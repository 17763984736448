.link {
    text-decoration: none;
}

.projectContainer {
    width: 24vw;
    text-align: center;
    background-color: #609966;
    border-radius: 10px;
    position: relative;
    z-index: 1;
}

.imgContainer {
    height: calc(30vh + 20px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    overflow: hidden;
}

.textContainer {
    text-align: left;
    padding: 20px 20px;
}

.image {
    // width: 50px;
    height: 90%;
    filter: drop-shadow(16px, 16px, black);
    padding-top: 20px;
}

.button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 68%;
}

.previewImg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.logo {
    width: 40px;
}

.labelContainer {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    gap: 10px;
}

@media only screen and (max-width: 768px) {
    .projectContainer {
        width: 60vw;
    }
    
    .textContainer {
        text-align: center;
    }

    .labelContainer {
        justify-content: center;
    }
}

@media only screen and (min-width: 768px) {
    .projectContainer {
        width: 28vw;
    }
} 